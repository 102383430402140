import React from "react";
import ComponentWow from "../../Wow"
import ImageGallery from 'react-image-gallery';
import { useTranslation } from "gatsby-plugin-react-i18next";

import { Portfolio as PortfolioConstants } from "../../../constants";

const Portfolio = () => {
  const { t } = useTranslation();


  return (
    <ComponentWow>
      <div className="container-fluid bg-portfolio py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
            
              <h4 className="h1 mb-4 section-title text-white">
              {t("homepage.portfolio.title")}
              </h4>
            </div>
          
          </div>
          <div className="row m-0 mt-4">
            <div className="col-12">
            <ImageGallery items={PortfolioConstants}  />

            </div>
          </div>
        </div>
      </div>
    </ComponentWow>
  );
};

export default Portfolio;
